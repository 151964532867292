import React from "react"
import './index.css'

const homePage = () => (
  <div className="homePage">
    <div className="inner">
      <h1>Clem Clean.</h1>
      <p>Stay tuned for more information coming soon.</p>
    </div>
  </div>
)

export default homePage;
